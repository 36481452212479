<template>
  <div class="newTicketInputParent">
    <form name="ticketInputForm" id="ticketInputForm" @submit="send">
      <div class="answerContainer" v-if="localAnswerTicket && !isInternal">
        <span class="fromSpan" v-if="localAnswerType == 'answer'"
          >{{ $t("mail.answer") }} {{ $t("general.on") }}
          {{ $t("ticket.ticket") }} {{ $t("general.fromObject") }}
          {{ localAnswerTicket.ticketSender.emailAddress }}
          {{ $t("general.fromDate") }}
          {{
            new Date(localAnswerTicket.creationDate * 1000).toLocaleString()
          }}</span
        >
        <span class="fromSpan" v-if="localAnswerType == 'answerAll'"
          >{{ $t("mail.answerAll") }} {{ $t("general.on") }}
          {{ $t("ticket.ticket") }} {{ $t("general.fromObject") }}
          {{ localAnswerTicket.ticketSender.emailAddress }}
          {{ $t("general.fromDate") }}
          {{
            new Date(localAnswerTicket.creationDate * 1000).toLocaleString()
          }}</span
        >
        <span class="fromSpan" v-if="localAnswerType == 'forwarding'"
          >{{ $t("mail.forwarding") }} {{ $t("general.fromObject") }}
          {{ $t("ticket.ticket") }} {{ $t("general.fromObject") }}
          {{ localAnswerTicket.ticketSender.emailAddress }}
          {{ $t("general.fromDate") }}
          {{ new Date(localAnswerTicket.creationDate * 1000).toLocaleString() }}
        </span>
        <button class="button input cancelButton" @click="cancelAnswer()">
          X
        </button>
        <div v-if="localAnswerType == 'forwarding'">
          <div v-if="localAnswerTicket.attachments">
            {{ $t("ticketInput.withTheFollowing") }}
            {{ $t("ticket.attachments") }}:
            <br />
          </div>
          <div
            class="attachmentsContainer"
            v-if="localAnswerTicket.attachments"
          >
            <span
              v-for="(attachment, index) in localAnswerTicket.attachments"
              v-bind:index="index"
              v-bind:key="attachment.primaryKey"
              class="attachment"
            >
              <nobr
                >{{ attachment.fileName }}
                <button
                  class="button input"
                  @click="
                    $event.preventDefault();
                    localAnswerTicket.attachments.splice(index, 1);
                  "
                >
                  X
                </button>
              </nobr>
            </span>
          </div>
        </div>
      </div>
      <table
        class="mailAdressesTable"
        v-if="ticketProcess.primaryKey && !isInternal"
      >
        <tr>
          <td class="mailLabelCell">
            <label>{{ $t("mail.to") }}</label>
          </td>
          <td>
            <input
              class="input mailInput"
              id="to"
              v-model="toAdressesString"
              @change="areEmailAdressesCorrect"
            />
          </td>
        </tr>
        <tr>
          <td class="mailLabelCell">
            <label>{{ $t("mail.cc") }}</label>
          </td>
          <td>
            <input
              class="input mailInput"
              id="cc"
              v-model="ccAdressesString"
              @change="areEmailAdressesCorrect"
            />
          </td>
        </tr>
      </table>

      <editor ref="editor"></editor>

      <span class="error" v-if="editorErrorMessage">{{
        editorErrorMessage
      }}</span>

      <div class="submitWrapper">
        <!-- <label id="attachmentsLabel" for="attachmentsInput">Anhänge: </label> -->
        <!-- <input id="attachmentsInput" name="file" type="file" multiple /> -->
        <span class="button input" v-on:click="openManageFilesModal()">{{
          $t("ticket.attachments")
        }}</span>
        &nbsp;
        <span>{{ $tc("general.file", files.length) }}</span>
        &nbsp;
        <select
          v-if="ticketProcess.primaryKey"
          id="signaturSelect"
          class="button input"
          name="signaturSelect"
          v-model="selectedSignature"
        >
          <option value="-1">{{ $tc("mail.signature", 0) }}</option>
          <template v-if="userArbitrary && userArbitrary.signatures">
            <option
              v-for="(signature, sigIndex) in userArbitrary.signatures"
              :key="sigIndex"
              :value="sigIndex"
            >
              {{ signature.name }}
            </option>
          </template>
        </select>
        <input
          v-if="ticketProcess.primaryKey"
          id="internCheckbox"
          type="checkbox"
          v-model="isInternal"
        />
        <label
          v-if="ticketProcess.primaryKey"
          id="interalLabel"
          class="button input"
          for="internCheckbox"
          >{{ $t("ticket.internal") }}</label
        >
        <button type="submit" id="sendButton" class="button input">
          <font-awesome-icon icon="paper-plane" /> {{ $t("mail.send") }}
        </button>
      </div>
    </form>

    <!-- Modal -->
    <div id="manageFileModal" class="modal">
      <div class="modal-content">
        <span class="closeButton" @click="cloaseMangeFilesModal()"
          >&times;</span
        >
        <vue-dropzone
          ref="dropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="fileAdded"
          @vdropzone-removed-file="fileRemoved"
        ></vue-dropzone>
      </div>
    </div>
  </div>
</template>

<script>
const TicketType = Object.freeze({
  Incoming: "Incoming",
  Outgoing: "Outgoing",
  Internal: "Internal",
});

import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";
import processFunctions from "@/functions/process";
import ticketFunctions from "@/functions/ticket";
import userFunctions from "@/functions/user";
import utilsFunctions from "@/functions/utils";
import recipientFunctions from "@/functions/recipient";
import customerFunctions from "../functions/customer";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Editor from "./Editor.vue";

export default {
  name: "NewTicketInput",
  props: {
    ticketProcess: Object,
    answerTicket: Object,
    answerType: String,
    customer: Object
  },
  components: {
    Editor,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      TicketType,
      localAnswerTicket: null,
      localAnswerType: null,
      toAdressesString: "",
      ccAdressesString: "",
      toAdresses: [Object],
      ccAdresses: [Object],
      recipients: null,
      userArbitrary: {},
      selectedSignature: -1,
      lastSelectedSignature: -1,
      isInternal: false,
      editorErrorMessage: "",
      fontFamily: "Arial, Helvetica, sans-serif",
      fontSize: "11pt",
      files: [],
      dropzoneOptions: {
        url: "null",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        maxFilesize: 5,
        addRemoveLinks: true,
        autoProcessQueue: false,
        dictDefaultMessage: this.$t("dropzone.defaultMessage"),
        dictFallbackMessage: this.$t("dropzone.fallbackMessage"),
        dictFileTooBig: this.$t("dropzone.fileTooBig"),
        dictRemoveFile: "X",
      },
    };
  },
  watch: {
    answerTicket: {
      handler: function () {
        this.localAnswerTicket = JSON.parse(JSON.stringify(this.answerTicket));
        this.localAnswerType = JSON.parse(JSON.stringify(this.answerType));
        this.initAdressesStrings();
        this.areEmailAdressesCorrect();
        this.setBodyFromAnswerTicket();
      },
      immediate: false,
    },
    answerType: {
      handler: function () {
        this.localAnswerTicket = JSON.parse(JSON.stringify(this.answerTicket));
        this.localAnswerType = JSON.parse(JSON.stringify(this.answerType));
        this.initAdressesStrings();
        this.areEmailAdressesCorrect();
      },
      immediate: false,
    },
    isInternal: function () {
      if (this.isInternal) {
        this.setBodyFromAnswerTicket();
      }
    },
    selectedSignature: function () {
      this.setSignatureInEditor();
    },
  },
  mounted() {
    this.initAdressesStrings();
  },
  methods: {
    openManageFilesModal() {
      var modal = document.getElementById("manageFileModal");
      modal.style.display = "block";
    },
    cloaseMangeFilesModal() {
      var modal = document.getElementById("manageFileModal");
      modal.style.display = "none";
    },
    cancelAnswer() {
      this.$emit("cancelAnswer");
    },
    setBodyFromAnswerTicket() {
      let body = this.getEditorHtml();
      var newBody = null;

      if (body.includes("---Ursprüngliche Nachricht---")) {
        newBody = body.substring(
          0,
          body.indexOf("-----Ursprüngliche Nachricht---") - 3
        );
      }

      if (this.localAnswerTicket && !this.isInternal) {
        if (!newBody) {
          newBody = `<div class"startTicketAnswer">${body}</div>`;
        }
        newBody += `<p>-----Ursprüngliche Nachricht-----<br />
Von: &lt;${this.localAnswerTicket.ticketSender.emailAddress}&gt;<br />
Gesendet: ${new Date(
          this.localAnswerTicket.creationDate * 1000
        ).toLocaleString()}<br />
Betreff: ${this.localAnswerTicket.subject}<br />
</p>`;

        // console.log("this.localAnswerTicket:", this.localAnswerTicket);

        let answerTicketDom = document.createElement("html");
        if (this.localAnswerTicket.htmlBody) {
          answerTicketDom.innerHTML = this.localAnswerTicket.htmlBody;
        } else {
          answerTicketDom.innerHTML = this.localAnswerTicket.textBody.replace(
            /[\n]/g,
            "<br>"
          );
        }
        let answerTicketBody = answerTicketDom.querySelector("body");

        if (answerTicketBody == null) {
          let bodyDom = document.createElement("body");
          answerTicketDom.appendChild(bodyDom);
        }

        answerTicketBody.innerHTML = newBody + answerTicketBody.innerHTML;
        newBody = answerTicketDom.outerHTML;
      }

      if (newBody) {
        this.setEditorHtml(newBody);
      }
    },
    setSignatureInEditor() {
      let body = this.getEditorHtml();

      // remove old signature
      if (
        this.lastSelectedSignature >= 0 &&
        this.userArbitrary &&
        this.userArbitrary.signatures
      ) {
        var oldSig =
          this.userArbitrary.signatures[this.lastSelectedSignature].text;
        if (body.includes(oldSig)) {
          var indexOS = body.indexOf(oldSig);
          var tmpBody = body.substring(0, indexOS);
          tmpBody += body.substring(indexOS + oldSig.length);

          body = tmpBody;
        }
      }

      // add new signature
      if (
        this.selectedSignature >= 0 &&
        this.userArbitrary &&
        this.userArbitrary.signatures
      ) {
        var newBody = null;

        var startText = "-----Ursprüngliche Nachricht-----";

        if (body.includes(startText)) {
          // find the start of the origin message
          var start = this.getIndexWithTag(body, startText);

          newBody = body.substring(0, start);

          // add the new signature between
          newBody =
            newBody +
            this.userArbitrary.signatures[this.selectedSignature].text;

          newBody += body.substring(start);
        } else {
          newBody =
            body + this.userArbitrary.signatures[this.selectedSignature].text;
        }

        if (newBody) {
          body = newBody;
        }
      }

      if (body) {
        this.setEditorHtml(body);
      }
      this.lastSelectedSignature = this.selectedSignature;
    },
    getIndexWithTag(text, search) {
      var index = text.indexOf(search);
      var newText = text.substring(0, index);
      index = newText.lastIndexOf("<");
      return index;
    },
    setEditorHtml(htmlText) {
      this.$refs.editor.setHtmlInput(htmlText);
    },
    getEditorHtml() {
      return this.$refs.editor.getHtmlInput();
    },
    initAdressesStrings() {
      var tmpToAdressesString = "";
      var tmpCcAdressesString = "";

      if (this.localAnswerTicket && this.localAnswerType != "forwarding") {
        // set the sender as first to adress
        tmpToAdressesString = this.localAnswerTicket.ticketSender.emailAddress;

        // set the other addresses if answerAll
        if (
          this.localAnswerTicket.recipients &&
          this.localAnswerType == "answerAll"
        ) {
          tmpToAdressesString +=
            ", " +
            recipientFunctions.generateRecipientsStringBy(
              this.localAnswerTicket.recipients,
              "To"
            );

          tmpCcAdressesString = recipientFunctions.generateRecipientsStringBy(
            this.localAnswerTicket.recipients,
            "Cc"
          );
        }
      }

      // set sender of TicketProcess as default if no recipients are given
      if (!tmpToAdressesString && this.localAnswerType != "forwarding") {
        tmpToAdressesString = this.ticketProcess.ticketSender.emailAddress;
      }
      this.toAdressesString = tmpToAdressesString;

      this.ccAdressesString = tmpCcAdressesString;
    },
    generateRecipients() {
      // console.log("toAdressesString: ", this.toAdressesString);
      // console.log("ccAdressesString: ", this.ccAdressesString);

      this.toAdresses = recipientFunctions.generateRecipientArrayByString(
        this.toAdressesString,
        "To"
      );

      this.ccAdresses = recipientFunctions.generateRecipientArrayByString(
        this.ccAdressesString,
        "Cc"
      );

      // console.log("toAdresses: ", this.toAdresses);
      // console.log("ccAdresses: ", this.ccAdresses);

      this.recipients = this.toAdresses.concat(this.ccAdresses);
    },
    areEmailAdressesCorrect() {
      this.generateRecipients();
      var toEmailInput = document.getElementById("to");
      var ccEmailInput = document.getElementById("cc");

      if (!toEmailInput || !ccEmailInput) {
        return false;
      }

      var emailInput = null;

      // reset both inputs that there is all right
      toEmailInput.setCustomValidity("");
      ccEmailInput.setCustomValidity("");

      if (toEmailInput.value == null || toEmailInput.value.trim() === "") {
        toEmailInput.setCustomValidity(this.$t("mail.pleaseEnterAEmailAdress"));
        toEmailInput.reportValidity();
        this.$store.dispatch(LOADING_STOPPED);
        return false;
      }

      // check if the email adresses are valid
      for (const recipient of this.recipients) {
        if (recipient.recipientType == "To") {
          emailInput = toEmailInput;
        } else if (recipient.recipientType == "Cc") {
          emailInput = ccEmailInput;
        } else {
          console.error("areEmailAdressesCorrect new TicketType");
          return false;
        }

        if (!emailInput) {
          console.error("areEmailAdressesCorrect emailInput is null");
          return false;
        }

        if (!utilsFunctions.isEmailAdressValid(recipient.emailAddress)) {
          // console.log("adress not valid: ", recipient.emailAddress);
          emailInput.setCustomValidity(
            this.$t("mail.pleaseEnterValidEmailAddressesWithSeperator")
          );
          // emailInput.reportValidity();
          this.$store.dispatch(LOADING_STOPPED);
          return false;
        }
      }

      return true;
    },
    async saveCustomer() {
      this.customer.emailAddress =
        this.ticketProcess.ticketSender.emailAddress.toLowerCase();
      if (!this.customer.primaryKey) {
        this.addCustomer();
      } else {
        this.updateCustomer();
      }
    },
    async addCustomer() {
      this.customer.emailAddress = this.ticketProcess.ticketSender.emailAddress.toLowerCase();
      console.log(this.customer);
      console.log(this.ticketProcess);
      await customerFunctions.addCustomer(
        this.customer,
        this.$store
      );
    },
    async updateCustomer() {
      await customerFunctions.updateCustomer(
        this.customer,
        this.$store
      );
      // console.log("updateCustomerName success: ", success);
      // if (success) {
      //   this.customerErrorMessage = "";
      //   this.editCustomerName = false;
      // } else {
      //   this.customerErrorMessage = this.$t("mail.invalidEmailAddress");
      // }
    },
    send(e) {
      e.preventDefault();
      this.createTicketOrSendAnswer();
    },
    async createTicketOrSendAnswer() {
      // if the ticketProcess has no primaryKey it is a new one
      var newTicket =
        !this.ticketProcess.primaryKey || this.ticketProcess.primaryKey == null;

      // checks
      if (newTicket) {
        // check subject
        var subjectInput = document.getElementById("subject");
        if (
          subjectInput &&
          (subjectInput.value == null || subjectInput.value.trim() == "")
        ) {
          subjectInput.setCustomValidity(this.$t("general.fillOutThisField"));
          subjectInput.reportValidity();
          this.$store.dispatch(LOADING_STOPPED);
          return;
        }

        // check if sender e-mail-adress is valid, otherwise return
        var senderEmailInput = document.getElementById("sender");
        if (
          senderEmailInput &&
          !utilsFunctions.checkEmailAdress(
            senderEmailInput,
            this.$t("mail.pleaseEnterAValidEmailAddress")
          )
        ) {
          this.$store.dispatch(LOADING_STOPPED);
          return;
        }

        // check pool
        var poolInput = document.getElementById("pool");
        if (
          poolInput &&
          (poolInput.value == null || poolInput.value.trim() == "")
        ) {
          poolInput.setCustomValidity(this.$t("general.fillOutThisField"));
          poolInput.reportValidity();
          this.$store.dispatch(LOADING_STOPPED);
          return;
        }
      } else {
        // don't allow to answer with no attached ticket
        if (!this.isInternal && !this.localAnswerTicket) {
          if (!this.areEmailAdressesCorrect()) {
            return;
          }
          this.editorErrorMessage = this.$t("ticketInput.selectAMessage");
          this.$store.dispatch(LOADING_STOPPED);
          return;
        } else {
          this.editorErrorMessage = "";
        }
      }

      let body = this.getEditorHtml();

      // check if there is a body
      var tmpBody = body.trim();
      tmpBody = tmpBody.substring(tmpBody.indexOf(">") + 1, tmpBody.length);
      tmpBody = tmpBody.substring(0, tmpBody.lastIndexOf("</"));
      tmpBody = tmpBody.trim();
      if (!tmpBody) {
        // console.log("no body");
        this.editorErrorMessage = this.$t("ticketInput.inputAMessage");
        this.$store.dispatch(LOADING_STOPPED);
        return;
      } else {
        this.editorErrorMessage = "";
      }

      // set font family
      var divContainer = document.createElement("div");
      divContainer.setAttribute(
        "style",
        "font-family: " + this.fontFamily + "; font-size: " + this.fontSize
      );
      divContainer.innerHTML = body;

      var ps = divContainer.getElementsByTagName("p");
      for (const p of ps) {
        // style p tags
        p.style = "margin: 0;";
        // fix empty lines
        if (p.innerHTML == "") {
          p.innerHTML = "<br>";
        }
      }

      body = divContainer.outerHTML;

      this.$store.dispatch(LOADING_STARTED);

      let attachments = await this.uploadAttachments();
      if (!attachments) {
        console.error("upload of file not worked");
        this.$store.dispatch(LOADING_STOPPED);
        return;
      }

      if (newTicket) {
        await this.createTicket(attachments, body);
      } else {
        await this.sendAnswer(attachments, body);
      }

      this.$store.dispatch(LOADING_STOPPED);
    },
    async createTicket(attachments, body) {
      // set the given things
      this.ticketProcess.attachments = attachments;
      this.ticketProcess.htmlBody = body;
      var tmpDiv = document.createElement("div");
      tmpDiv.innerHTML = body;
      this.ticketProcess.textBody = tmpDiv.textContent;
      this.ticketProcess.agent = this.$store.getters.userId;

      let primaryKey = await processFunctions.createProcess(
        this.ticketProcess,
        this.$store
      );

      if(this.customer.primaryKey || (this.customer.firstName || this.customer.name || this.customer.organisation)) {
        await this.saveCustomer(this.customer);
      }

      if (primaryKey) {
        // navigate to the TicketProcess
        this.$router.push(
          this.$i18nRoute({ name: "Ticket", params: { processId: primaryKey } })
        );
      }
    },
    async sendAnswer(attachments, body) {
      var ticketAnswer = new Object();

      // add attachments from localAnswerTicket
      if (
        this.localAnswerTicket &&
        this.localAnswerTicket.attachments &&
        this.localAnswerType == "forwarding"
      ) {
        for (const attachment of this.localAnswerTicket.attachments) {
          var tmpAttachment = new Object();
          tmpAttachment.fileName = attachment.fileName;
          tmpAttachment.name = attachment.name;
          attachments.push(tmpAttachment);
        }
      }

      ticketAnswer.attachments = attachments;
      ticketAnswer.htmlBody = body;
      var tmpDiv = document.createElement("div");
      tmpDiv.innerHTML = body;
      ticketAnswer.textBody = tmpDiv.textContent;
      ticketAnswer.agent = this.$store.getters.userId;

      if (this.isInternal) {
        ticketAnswer.type = TicketType.Internal;
      } else {
        ticketAnswer.type = TicketType.Outgoing;
      }

      // add recipients if it is outgoing
      if (ticketAnswer.type == TicketType.Outgoing) {
        this.generateRecipients();

        if (this.recipients) {
          if (this.recipients && this.recipients.length > 0) {
            this.recipients = this.recipients.filter((recipient) => {
              return (
                recipient != null &&
                recipient.emailAddress != null &&
                recipient.emailAddress != ""
              );
            });

            ticketAnswer.recipients = this.recipients;
          }
        } else {
          // console.log(
          //   "no recipients set. The backend will use the E-Mail-address of the last ticket."
          // );
        }

        // console.log(
        //   "sendAnswer ticketAnswer.recipients: ",
        //   ticketAnswer.recipients
        // );
      }

      // set the reference
      if (this.localAnswerTicket) {
        ticketAnswer.ticketReference = this.localAnswerTicket.primaryKey;
      }

      let subject = null;

      if (this.localAnswerType == "forwarding") {
        subject = "Fwd: [".concat(
          this.ticketProcess.ticketCode,
          "] ",
          this.ticketProcess.subject
        );
      } else {
        subject = "Re: [".concat(
          this.ticketProcess.ticketCode,
          "] ",
          this.ticketProcess.subject
        );
      }

      if (subject) {
        ticketAnswer.subject = subject;
      }

      // console.log(
      //   "sendAnswer JSON TicketAnswer: " + JSON.stringify(ticketAnswer)
      // );

      // send answer
      let success = await ticketFunctions.addAnswer(
        this.ticketProcess.primaryKey,
        ticketAnswer,
        this.$store
      );

      this.$store.dispatch(LOADING_STOPPED);

      if (success) {
        this.setEditorHtml("<p><br></p>");
        this.files = [];
        this.$refs.dropzone.removeAllFiles();
        this.cancelAnswer();
        this.$emit("newAnswerAdded");
      }
    },
    fileAdded(file) {
      // console.log("addFile file:", file);
      this.files.push(file);
    },
    fileRemoved(file) {
      // console.log("fileRemoved file:", file);
      var indexOfFile = this.files.indexOf(file);
      this.files.splice(indexOfFile, 1);
    },
    async uploadAttachments() {
      var attachments = [];
      // var fileElement = document.getElementById("attachmentsInput");
      //fileElement.files

      // console.log("uploadAttachments files:", this.files);

      for (const file of this.files) {
        var blobFileName = await ticketFunctions.uploadFile(file);

        // console.log(
        //   "uploadAttachments blobFileName from uploadFile: ",
        //   blobFileName
        // );

        if (blobFileName == undefined || blobFileName == null) {
          return null;
        }

        var attachment = new Object();

        attachment.fileName = file.name;
        attachment.name = blobFileName;

        attachments.push(attachment);
      }
      return attachments;
    },
    async loadUserArbitrary() {
      this.userArbitrary = (
        await userFunctions.getUserArbitrary(this.$store, [
          this.$store.getters.userId,
        ])
      )[0].object;
      if (this.userArbitrary && this.userArbitrary.signatures) {
        // if signatures are of type string try to parse them to JSON
        if (typeof this.userArbitrary.signatures == "string") {
          let tmpSignatures = JSON.parse(this.userArbitrary.signatures);
          this.userArbitrary.signatures = tmpSignatures;
        }
      }
      // console.log("loadUserArbitrary this.userArbitrary: ", this.userArbitrary);
    },
  },
  beforeMount: function () {
    this.loadUserArbitrary();
  },
};
</script>

<style lang="scss" scoped>
.newTicketInputParent {
  // padding: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 8em;
  max-height: 70vh;
  overflow: hidden;
}

#attachmentsLabel {
  align-content: center;
  margin-left: 0.25em;
}

#attachmentsInput {
  align-content: center;
}

#internCheckbox {
  display: none;
}

#internCheckbox:checked + #interalLabel {
  background: #333;
  color: #d4d4d4;
}

// #signaturSelect,
// #sendButton,
// #interalLabel {
//   text-align: center;
//   // margin-left: auto;
//   border: 0;
//   color: #333;
//   background: #e4e4e4;
//   border-radius: 0.25em;
//   cursor: pointer;
//   margin: 0.25em 0.125em;
//   padding: 0.25em;
//   box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
// }

// #sendButton {
//   margin-right: 0.25em;
// }

.submitWrapper {
  //display: flex;
  text-align: right;
  padding: 0.125em;

  #signaturSelect {
    margin-left: auto;
  }

  .button {
    // border-left: 1px solid #D4D4D4;
    margin-left: 1em;
    margin: 0.125em;
    // border: 0;
    // box-shadow: none;
    // background: #333;
    // color: #e4e4e4;
    border-radius: 0;
  }
}

// Only Safari 10.1+
// @media not all and (min-resolution: 0.001dpcm) {
//   .submitWrapper {
//     display: unset;
//   }
// }

#ticketInputForm {
  padding: 0.5em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.cancelButton {
  margin-left: 0.5em;
}

.mailAdressesTable {
  width: 100%;
  border-spacing: 10px;
  border-collapse: separate;
}

.mailLabelCell {
  text-align: center;
}

.mailInput {
  width: 100%;
}

.answerContainer {
  margin: 0.5em;
}

.attachmentsContainer {
  overflow-y: auto;
  max-height: 105px;
  border-radius: 0.5em;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.attachment {
  display: inline-flex;
  background-color: #e4e4e4;
  margin: 0.1em;
  padding: 0.1em;
  border-style: solid;
  border-color: #e4e4e4;
  border-radius: 0.5em;
}
</style>

<style lang="scss">
#dropzone .dz-preview .dz-image {
  width: 160px;
  height: 130px;
  margin-left: 40px;
  margin-bottom: 10px;
}

#dropzone .dz-progress {
  display: none;
}
</style>