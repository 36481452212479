// const RecipientTypes = Object.freeze({
//     To: "To",
//     Cc: "Cc",
//     Bcc: "Bcc",
// });

var recipientFunctions = {
    generateRecipientsStringBy(recipients, recipientType) {
        let tmpRecipientsString = "";

        // console.log("generateRecipientsStringBy: ", recipients, recipientType);
        if (recipients) {
            for (const recipient of recipients) {
                if (recipient.recipientType == recipientType) {
                    if (tmpRecipientsString != "") {
                        tmpRecipientsString += ", ";
                    }
                    tmpRecipientsString += recipient.emailAddress;
                }
            }
        }

        return tmpRecipientsString;
    },
    generateRecipientArrayByString(recipientsString, recipientType) {
        // console.log("generateRecipientArrayByString", recipientsString);
        let recipientsParts = recipientsString.split(/[(,|;| )]/);
        // console.log("recipientsParts: ", recipientsParts);

        recipientsParts = recipientsParts.filter((recPart) => {
            return recPart != "";
        });

        var recipients = [];

        for (const recPart of recipientsParts) {
            var rec = new Object();
            rec.emailAddress = recPart.trim();
            rec.recipientType = recipientType;
            recipients.push(rec);
        }

        // console.log("recipients: ", recipients);

        return recipients;
    },
}

export default recipientFunctions;